import * as React from "react";
import _ = require("lodash");
import {BaseView} from "./BaseView";
import {uiState} from "../app";

export class BilanzView extends BaseView {

    render() {

        const {post} = this.state;

        const getLastTitle = (table, index) => {
            const titles = _(table.rows).take(index).filter((row) => row.type === "title");

            return (titles.length !== 0)
                ? titles.last().label
                : false;
        }

        return <section className="grid-text">

            <h1>{post.pageTitle}</h1>

            {post.tables.map((table, index) => <div key={index} >
                <h2>{table.title}</h2>
                <div className="small-date">CHF</div>
                <table className="board">
                    <tbody>
                        {table.rows.map((row, rowIndex) => {

                            let rowClasses = [];

                            if(row.type === "subtotal" || row.type === "total")
                                rowClasses.push("sum-table");

                            if(row.type === "total")
                                rowClasses.push("sum-final-table");

                            switch (row.type) {
                                case "title":
                                    return <tr key={rowIndex}>
                                        <th>{row.label}</th>
                                        <th>{post.year}</th>
                                        <th>{post.lastYear}</th>
                                    </tr>
                                case "subtotal":
                                case "total":
                                    return [<tr className={rowClasses.join(" ")}>
                                            <td data-th={getLastTitle(table, rowIndex+1)}>{row.label}</td>
                                            <td data-th={post.year}>{row.valueYear.toLocaleString("de-CH")}</td>
                                            <td data-th={post.lastYear}>{row.valueLastYear.toLocaleString("de-CH")}</td>
                                        </tr>,
                                        <tr className="separator">
                                            <td>-</td>
                                        </tr>]
                                default:
                                    return <tr key={rowIndex}>
                                        <td data-th={getLastTitle(table, rowIndex+1)}>{row.label}</td>
                                        <td data-th={post.year}>{row.valueYear.toLocaleString("de-CH")}</td>
                                        <td data-th={post.lastYear}>{row.valueLastYear.toLocaleString("de-CH")}</td>
                                    </tr>
                            }
                        })}
                    </tbody>
                </table>
            </div>)}

            <div className="content" dangerouslySetInnerHTML={{__html: post.content}} />

        </section>
    }
}

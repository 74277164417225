import * as React from "react";
import _ = require("lodash");
import {uiState} from "../app";
import {Section} from "../models/Section";

export interface PillProps {
    section: Section;
    update: Function;
    active: Boolean;
}

export class Pill extends React.Component<PillProps, any> {

    render() {

        const {section, update, active} = this.props;

        if(_.isUndefined(section))
            return <span></span>;


        const container = ["pill-container", "pill-"+section.code].join(" ");
        const pill = ["pill", "bg-"+section.color, active?"active":""].join(" ");

        return <div key={section.code} className={container} onClick={() => update() } >
            <div className={pill}><span>{section.code}</span></div>
        </div>
    }
}

import {observable, isObservableArray} from "mobx";
import {HttpError, ProcessingError} from "./error"
import _ = require("lodash");
import {uiState} from "../app";
import camelCase = require('camel-case');
import snakeCase = require('snake-case');
import deepMapKeys = require('deep-map-keys');
import {fromByteArray} from "base64-js";

export enum HttpMethod {
    GET, POST, PATCH, DELETE
}

type Constructor<T> = new(...args: any[]) => T;

export class Api {
    private url: string;
    private authToken: string = null;
    private lang: string = null;

    constructor(url: string) {
        this.url = url;
    }

    setLanguage(lang: string) {
        this.lang = lang
    }

    private static handleFetchStatus = (response: Response) => {
        if(response.ok) {
            return Promise.resolve("");
        }
        else {
            return response
                .json()
                .then(
                    x => { throw new HttpError(response.statusText, x) },
                    () => { throw new HttpError(response.statusText, response.statusText) }
                )
        }
    };

    private static convertToJson: (Response) => Promise<any> = (x: Response) => {
        if(x.status === 204) {
            return Promise.resolve("");
        }
        else {
            return x.json().catch(x => {
                console.error(x); // Nice polyfill swallow error
                throw new ProcessingError("Bad data received");
            });
        }
    };



    private static instantiateObjectsFromResponse = (klass, store) => response => {

        if(_.isUndefined(klass))
            return response;
        if(_.isArray(response)) {
            return response.map(item => new klass(_.assign({}, item, { store })));
        }
        else {
            return new klass(_.assign({}, response, { store }));
        }
    };

    private static convertKeysToCamelCase = data => deepMapKeys(data, camelCase);

    private static methodsToString: (HttpMethod) => string =
        (method: HttpMethod) =>
            ({
                [HttpMethod.GET]: "GET",
                [HttpMethod.POST]: "POST",
                [HttpMethod.PATCH]: "PATCH",
                [HttpMethod.DELETE]: "DELETE"
            }[method]);

    private static toBase64(toConvert: string) {
        return fromByteArray(_.map(toConvert, char => char.charCodeAt(0)));
    }

    private call: <T>(HttpMethod, boolean) => (string) => (any?) => (x?: Constructor<T>, any?) => Promise<Array<T>> =
        (method: HttpMethod, isUpload: boolean = false) => (path: string) => data => (klass, store) => {
            const layer = Api;
            const btoa = Api.toBase64;

            const headers = new Headers();

            headers.append('Accept', 'application/json; charset=UTF-8');
            headers.append('Access-Control-Allow-Credentials', "*");
            headers.append('Accept-Language', this.lang);

            headers.append("Authorization", `Basic ${btoa("hawaii:Hawaii2017")}`);

            const body = data;

            return fetch(
                `${this.url}${path}?t=${new Date().getTime()}`,
                {
                    method: layer.methodsToString(method),
                    headers,
                    body,
                    mode: 'cors'
                }
            )
                //.then(layer.handleFetchStatus)
                .then(layer.convertToJson)
                .then(layer.convertKeysToCamelCase)
                .then(layer.instantiateObjectsFromResponse(klass, store))
        };

    private callCollection: <T>(HttpMethod, boolean) => (string) => (any?) => (x?: Constructor<T>, store?: any) => Promise<Array<T>> = this.call;

    getRequest = this.call(HttpMethod.GET, false);
    getCollection = this.callCollection(HttpMethod.GET, false);
}

import * as React from "react";
import { observer } from "mobx-react";
import _ = require("lodash");
import { Link } from "react-router-dom";
import { Post } from "../models/Post";
import { Image } from "./Image";
import { IconVideo } from "../svg/IconVideo";
import { IconZahlen } from "../svg/IconZahlen";
import { SwissMap } from "../svg/SwissMap";
import { Doughnut } from "react-chartjs-2";
import * as Waypoint from "react-waypoint";

export interface MasonryItemProps {
    post: Post;
    big?: string;
}

@observer
export class MasonryItem extends React.Component<MasonryItemProps, any> {
    static defaultProps = {
        big: false
    };

    refs: {
        chart: any;
    };

    constructor(props, context) {
        super(props, context);

        const { post } = props;
        this.state = {
            chartData: MasonryItem.generateChartData(props),
            hasPillsAnimation: false,
            isActive: false
        };
    }

    componentWillReceiveProps(nextProps: MasonryItemProps, nextContext) {
        this.setState({ chartData: MasonryItem.generateChartData(nextProps) });
    }

    static generateChartData(props: MasonryItemProps) {
        if (!props || !props.post || props.post.type !== "zahlen")
            return undefined;

        return {
            datasets: [
                {
                    data: props.post.sectors.map(sector => sector.total),
                    backgroundColor: [
                        "#56524E",
                        "#BD5E4C",
                        "#AFD2D7",
                        "#DAA529"
                    ],
                    hoverBackgroundColor: [
                        "#56524E",
                        "#BD5E4C",
                        "#AFD2D7",
                        "#DAA529"
                    ],
                    borderColor: [
                        "transparent",
                        "transparent",
                        "transparent",
                        "transparent"
                    ]
                }
            ]
        };
    }

    render() {
        const { post, big } = this.props;

        if (_.isUndefined(post)) return <div />;

        const { chartData } = this.state;

        const itemClasses = ["masonry-item", "item-big-" + big].join(" ");
        const articleClasses = [
            "masonry-item-content",
            "color-" + post.color,
            "bg-" + post.background
        ].join(" ");

        const size = big ? 1400 : 800;

        const computeChartData = () =>
            this.setState({
                chartData: MasonryItem.generateChartData(this.props)
            });

        const { hasPillsAnimation } = this.state;
        const buildPillsClassName = (...classNames) =>
            []
                .concat(classNames, hasPillsAnimation ? "animation" : [])
                .join(" ");

        // FIXME: stop using string where you can use enum !!!
        return (
            <Link to={post.getLink()} className={itemClasses}>
                <Waypoint
                    onEnter={() => {
                        this.setState({ isActive: true });
                    }}
                    onLeave={() => {
                        this.setState({ isActive: false });
                    }}
                >
                    <article className={articleClasses}>
                        <h2 dangerouslySetInnerHTML={{ __html: post.title }} />

                        {post.subtitle && <small>{post.subtitle}</small>}

                        {post.type === "video" && <IconVideo />}

                        {post.picture && (
                            <div>
                                <Image
                                    src={post.picture}
                                    height={size}
                                    width={size}
                                    smart={true}
                                />
                                <div className="overlay" />
                            </div>
                        )}

                        {post.type === "zahlen" && (
                            <div>
                                <Waypoint
                                    onEnter={() => {
                                        computeChartData();
                                    }}
                                />
                                <div id="myChart">
                                    <Doughnut
                                        ref="chart"
                                        redraw={true}
                                        data={chartData}
                                        options={{
                                            cutoutPercentage: 70,
                                            tooltips: false,
                                            animation: { duration: 2000 }
                                        }}
                                        width={220}
                                        height={220}
                                    />
                                </div>
                            </div>
                        )}

                        {post.type === "section" && (
                            <div className="pills-home-container">
                                <Waypoint
                                    onEnter={() => {
                                        this.setState({
                                            hasPillsAnimation: true
                                        });
                                    }}
                                    onLeave={() => {
                                        this.setState({
                                            hasPillsAnimation: false
                                        });
                                    }}
                                />
                                <div
                                    className={buildPillsClassName(
                                        "pill pill-m pill-1 bg-bleu"
                                    )}
                                />
                                <div
                                    className={buildPillsClassName(
                                        "pill pill-l pill-2 bg-bleu"
                                    )}
                                />
                                <div
                                    className={buildPillsClassName(
                                        "pill pill-s pill-3 bg-jaune"
                                    )}
                                />
                                <div
                                    className={buildPillsClassName(
                                        "pill pill-s pill-4 bg-jaune"
                                    )}
                                />
                                <div
                                    className={buildPillsClassName(
                                        "pill pill-m pill-5 bg-jaune"
                                    )}
                                />
                                <div
                                    className={buildPillsClassName(
                                        "pill pill-l pill-6 bg-rouge"
                                    )}
                                />
                                <div
                                    className={buildPillsClassName(
                                        "pill pill-s pill-7 bg-rouge"
                                    )}
                                />
                                <div
                                    className={buildPillsClassName(
                                        "pill pill-s pill-8 bg-dark"
                                    )}
                                />
                                <div
                                    className={buildPillsClassName(
                                        "pill pill-l pill-9 bg-dark"
                                    )}
                                />
                            </div>
                        )}

                        {post.type === "section" && <SwissMap />}
                    </article>
                </Waypoint>
            </Link>
        );
    }
}

import _ = require("lodash");
import { computed } from "mobx";
import {Media} from "./Media";

interface YearConstructor {
    year: number;
    individualMembers: number;
    associateMembers: number;
    headquarters: number;
    branch: number;
}

export class Year {
    year: number;
    individualMembers: number;
    associateMembers: number;
    headquarters: number;
    branch: number;

    constructor(args: YearConstructor) {
        Object.keys(args).map(k =>  {
            this[k] = args[k]
        });
    }


    @computed public get totalMembers(){
        return this.individualMembers + this.associateMembers;
    }

    @computed public get totalOffices(){
        return this.headquarters + this.branch;
    }

}

import _ = require("lodash");
import {Media} from "./Media";



interface PeopleListConstructor {
    category: string;
    people: Person[];
}

export class PeopleList {
    category: string;
    people: Person[];

    constructor(args: PeopleListConstructor) {
        Object.keys(args).map(k =>  {
            if(k === "people")
                this.people = args[k].map( (element) => new Person(element) );
            else
                this[k] = args[k]
        });
    }

}


interface PersonConstructor {
    name: string;
    title: string;
    role: string;
    photo: Media;
}

export class Person {
    name: string;
    title: string;
    role: string;
    photo: Media;

    constructor(args: PersonConstructor) {
        Object.keys(args).map(k =>  {
            if(k === "photo")
                this.photo = this[k] = new Media(args[k]);
            else
                this[k] = args[k]
        });
    }

}

import * as React from "react";
import _ = require("lodash");
import {uiState} from "../app";
import {GalleryBlock} from "../elements/GalleryBlock";
import {Post} from "../models/Post";
import {BaseView} from "./BaseView";


export class ListView extends BaseView  {

    render() {

        const {post} = this.state;

        return <section className="grid-text">

            <h1>{post.pageTitle}</h1>

            {post.linksList.map( (linkList, index) =>
                <div key={index} className="list-wrapper list-societies">
                    <div className="list-item">
                        <div className="list-title">
                            <span>{linkList.title}</span>
                        </div>
                        <div className="list-text">
                            <ul>
                                {linkList.links.map( (link, index) =>
                                    <li key={index}>
                                        { !_.isEmpty(link.link)
                                            ? <a target="_blank" href={link.link}>{link.name}</a>
                                            : <div>{link.name}</div>
                                        }
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>

                </div>
            )}

        </section>
    }
}

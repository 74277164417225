import * as React from "react";
import { observer } from "mobx-react";
import _ = require("lodash");
import { Person } from "../models/Person";
import { Image } from "./Image";

export interface PersonItemProps {
    person: Person;
}

@observer
export class PersonItem extends React.Component<PersonItemProps, any> {
    static defaultProps = {
        big: "none"
    };

    render() {
        const { person } = this.props;

        if (_.isUndefined(person)) return <div />;

        const articleClasses = ["masonry-item", "leute"].join(" ");

        return (
            <article className={articleClasses}>
                <div className="masonry-item-content">
                    <Image
                        src={person.photo.src}
                        alt={person.photo.title}
                        height={800}
                        width={800}
                        smart={true}
                    />
                </div>
                <div className="leute-infos">
                    <h2>{person.name}</h2>
                    <small>{person.title}</small>
                    <small>{person.role}</small>
                </div>
            </article>
        );
    }
}

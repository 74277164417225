import * as React from "react";
import {Section} from "../models/Section";
import {t} from "../store/I18nStore";
import {observer} from "mobx-react";
import {sortBy} from "lodash";
import {Ease} from 'react-ease';

export interface SectionFactsProps {
    section: Section;
    update?: Function;
}

@observer
export class SectionFacts extends React.Component<SectionFactsProps, any> {

    render() {
        const {section, children} = this.props;

        const sections = (({ architecture, technical, civilEngineering, environment } ) => {
            if(!section)
                return [];

            return sortBy([
                { order: 0, label: t("profession.architektur"), value: architecture, className: "architecture" },
                { order: 2, label: t("profession.technik"), value: technical, className: "technique" },
                { order: 1, label: t("profession.ingenieurbau"), value: civilEngineering, className: "genie-civil" },
                { order: 3, label: t("profession.umwelt"), value: environment, className: "environnement" },
            ], x => x.order);
        })(section);

        const buildSection = ({ label, value, className }) =>
            <li key={ className }>
                <span>{ label }</span>
                <Ease key={value} from={0} to={value} duration={1500}>
                    {val => <span className={ className }>{parseInt(val)}</span>}
                </Ease>
            </li>;

        return <div className="graphe-facts">
            <div className="year-select">
                <div className="section-name-container">
                    <span>{ t("section.sektion") }</span>
                    <div className="year">
                        {children}
                    </div>
                </div>
                {section &&
                    <div className="section-number-container">
                        <span>{ t("section.mitglieder") }</span>
                        <Ease key={section.total} from={0} to={section.total} duration={1500}>
                            {val => <div className="number">{parseInt(val)}</div>}
                        </Ease>
                    </div>}
            </div>
            {section &&
                <ul>
                    <li>
                        <span>{ t("section.mitglieder_pro_berufsgruppe") }</span>
                    </li>
                    { sections.map(buildSection) }
                </ul>}
        </div>
    }
}

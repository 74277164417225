import * as React from "react";
import _ = require("lodash");
import {uiState} from "../app";
import {GalleryBlock} from "../elements/GalleryBlock";
import {Post} from "../models/Post";
import {BaseView} from "./BaseView";


export class GalleryView extends BaseView  {

    render() {

        const {post} = this.state;

        return <section className="masonry-container masonry-galerie">
            <h1>{post.pageTitle}</h1>
            {_.chunk(post.galleries[0].medias, 7).map((gallery, key) =>
                <GalleryBlock key={key} gallery={gallery} post={post} />
            )}
        </section>
    }
}

import _ = require("lodash");
import {observable, reaction, computed} from "mobx";


interface TableConstructor {
    rows: Row[];
}

export class Table {
    title: string;
    rows: Row[];

    constructor(args: TableConstructor) {
        Object.keys(args).map(k =>  {
            if(k === "rows")
                this.rows = args[k].map( (element) => new Row(element) );
            else
                this[k] = args[k]
        });
    }

}


interface RowConstructor {
    type: string;
    label: string;
    valueYear: number;
    valueLastYear: number;
}

export class Row {
    type: string;
    label: string;
    valueYear: number;
    valueLastYear: number;

    constructor(args: RowConstructor) {
        Object.keys(args).map(k =>  this[k] = args[k]);
    }

}

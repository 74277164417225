import * as React from "react";



export class LogoSia extends React.Component<any, any> {

    render() {
        return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 45.4">
            <path d="M3.2 35.5c0 .1-.1.1 0 0C2.1 37.7 1.1 39.9 0 42c-.1.2 0 .2.1.3.5.2.9.5 1.4.7 2.8 1.4 5.7 2.1 8.8 2.3 1.7.1 3.4 0 5-.5 2.5-.7 4.4-2.1 5.8-4.3 1.2-1.9 1.5-4.1 1.3-6.3-.3-2.4-1.3-4.4-3.2-5.9-1.3-1-2.8-1.8-4.3-2.5-1.2-.5-2.4-1.1-3.5-1.6-.6-.3-1-.6-1.4-1.1-.9-1-.6-2.5.5-3.2.6-.3 1.2-.5 1.8-.5 1.8-.1 3.5.4 5.1 1.2.6.3 1.2.6 1.8 1 1-2 1.9-3.9 2.9-5.9-.1-.1-.2-.1-.3-.2-2.7-1.5-5.5-2.3-8.6-2.5-2.1-.1-4.2.2-6.2 1-2.4 1.1-4 2.6-5 4.9-.6 1.4-.7 2.8-.6 4.3.1 2.1.9 3.8 2.4 5.3 1.1 1.1 2.3 1.9 3.7 2.5 1.4.7 2.9 1.4 4.4 2 .6.3 1.2.7 1.7 1.2 1 1 1.1 2.4.2 3.3-.6.6-1.4.8-2.3.9-1.8.1-3.5-.3-5.1-1.1-1.2-.4-2.2-1.1-3.2-1.8zM39.3 13.8V20h8v24.7h8v-.3-30.3c0-.4 0-.4-.4-.4H39.6c-.1.1-.2.1-.3.1zM51.1 0c-2.4-.1-4.8 1.8-4.8 4.8 0 2.6 2 4.8 4.8 4.8s4.8-2.2 4.8-4.8c0-2.9-2.4-4.9-4.8-4.8z"/>
            <path d="M3.2 35.5c1 .7 2.1 1.3 3.2 1.8 1.6.8 3.3 1.2 5.1 1.1.9-.1 1.7-.3 2.3-.9.9-.9.8-2.4-.2-3.3-.5-.5-1.1-.9-1.7-1.2-1.5-.7-2.9-1.3-4.4-2-1.3-.7-2.6-1.4-3.7-2.5-1.4-1.5-2.2-3.2-2.4-5.3-.1-1.5 0-2.9.6-4.3.9-2.3 2.6-3.8 4.9-4.7 2-.8 4.1-1.1 6.2-1 3.1.2 5.9 1 8.6 2.5.1 0 .2.1.3.2-1 2-1.9 3.9-2.9 5.9-.6-.3-1.2-.7-1.8-1-1.6-.8-3.3-1.3-5.1-1.2-.6 0-1.2.2-1.8.5-1.1.7-1.4 2.1-.5 3.2.4.5.9.9 1.4 1.1 1.2.5 2.3 1.1 3.5 1.6 1.5.7 3 1.4 4.3 2.5 1.9 1.5 3 3.5 3.2 5.9.2 2.2-.1 4.3-1.3 6.3-1.4 2.2-3.3 3.6-5.8 4.3-1.7.5-3.3.6-5 .5-3.1-.2-6-1-8.8-2.3-.5-.2-.9-.5-1.4-.7V42c1.1-2.1 2.1-4.3 3.2-6.5-.1.1 0 .1 0 0zM39.3 13.8H54.9c.4 0 .4 0 .4.4v30.6h-8V20.1h-8v-6.3zM51.1 0c2.4-.1 4.8 1.9 4.8 4.8 0 2.6-2 4.8-4.8 4.8s-4.8-2.2-4.8-4.8c0-3 2.4-4.9 4.8-4.8zM102 44.7v-.2c-.7-3.2-1.1-6.5-1.1-9.8V23.9c0-1.4-.2-2.7-.6-4-1-3-2.9-5-5.9-6-2-.6-4-.7-6-.7-2.4.1-4.8.5-7.2 1.1-.7.2-1.3.4-2 .6.6 2 1.2 4 1.9 6 .1 0 .2-.1.2-.1 1.2-.4 2.3-.8 3.5-1.1 1.5-.4 3-.5 4.5-.3 1.2.2 2.1.7 2.8 1.7.7 1.1.7 2.3.7 3.5h-.2c-1-.1-2-.3-3-.4-2.4-.2-4.7.1-6.9.9-2.4 1-4.2 2.6-5.3 5-.6 1.2-.8 2.6-.9 3.9-.1 1.7 0 3.3.5 4.9.8 2.7 2.5 4.6 5.1 5.7 1.9.8 3.8.9 5.8.5 2.7-.5 4.8-1.8 6.3-4.2 0-.1.1-.1.2-.2v.3c.2 1.1.4 2.3.5 3.4 0 .2.1.3.3.3H102.1h-.1zm-9.5-9.3c-.3 1.2-.9 2.3-2.1 2.9-1.3.7-3.2.8-4.5-.4-.6-.6-.9-1.3-1.1-2.1-.3-1.5-.1-3.1.7-4.4.9-1.4 2.2-2 3.8-2 1.1 0 2.1.2 3.2.5.2.1.3.1.3.4v1.8c.1 1 0 2.1-.3 3.3z"/>
        </svg>
    }
}
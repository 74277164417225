import * as React from "react";
import _ = require("lodash");
import {uiState} from "../app";
import {DefaultComponentProps} from "../store/DefaultStoreInterface";

export class BaseView extends React.Component<DefaultComponentProps, any> {

    constructor(props) {
        super(props);

        const {postSlug} = this.props.match.params;
        uiState.postSlug = postSlug;
        const post = uiState.getPostBySlug(postSlug);
        if(_.isUndefined(post)){
            this.props.history.push(uiState.translateUrl("/"));
            return;
        }

        uiState.setTitle(post.title);

        this.state = {post};
    }

    componentDidMount () {
        window.scrollTo(0, 0);
    }

    componentWillReceiveProps (nextProps) {
        window.scrollTo(0, 0);
        const {postSlug} = nextProps.match.params;
        uiState.postSlug = postSlug;

        const post = uiState.getPostBySlug(postSlug);
        if(!_.isUndefined(post)) {
            uiState.setTitle(post.title);
            this.setState({post});
        }
    }

    render() {
        return <div></div>;
    }
}

import * as React from "react";
import _ = require("lodash");
import {Post} from "../models/Post";
import {MasonryItem} from "./MasonryItem";

export interface MasonryBlockProps {
    posts: Post[];
}


export class MasonryBlock extends React.Component<MasonryBlockProps, any>  {

    render() {
        const {posts} = this.props;

        return <div className="row">

            <MasonryItem post={posts[0]} />

            { !_.isUndefined(posts[1]) &&
                <MasonryItem post={posts[1]} />}

            { !_.isUndefined(posts[2]) &&
                <MasonryItem post={posts[2]} />}

        </div>
    }
}

export class MasonryBlockBigLeft extends React.Component<MasonryBlockProps, any>  {

    render() {
        const {posts} = this.props;

        return <div className="row">

            <MasonryItem post={posts[0]} big={"left"} />

            <div className="row-right">
                { !_.isUndefined(posts[1]) &&
                    <MasonryItem post={posts[1]} />}

                { !_.isUndefined(posts[2]) &&
                    <MasonryItem post={posts[2]} />}
            </div>
        </div>
    }
}


export class MasonryBlockBigRight extends React.Component<MasonryBlockProps, any>  {

    render() {
        const {posts} = this.props;

        return <div className="row">
            <div className="row-left">

                <MasonryItem post={posts[0]}/>

                { !_.isUndefined(posts[1]) &&
                    <MasonryItem post={posts[1]} />}
            </div>

            { !_.isUndefined(posts[2]) &&
                <MasonryItem post={posts[2]} big={"right"} />}
        </div>
    }
}

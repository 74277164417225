import _ = require("lodash");
import {uiState} from "../app";
import { computed } from "mobx";
import {Gallery} from "./Media";
import {PeopleList} from "./Person";
import {Year} from "./Year";
import {Sector} from "./Sector";
import {Age} from "./Age";
import {Membership} from "./Membership";
import {LinkList} from "./Links";
import {Fact} from "./Fact";
import {Section} from "./Section";
import {Table} from "./Table";

interface PostConstructor {
    type: string;
    slugs: object;
    title: string;
    pageTitle?: string;
    subtitle?: string;
    content: string;
    signature: string;
    color?: string;
    background?: string,
    picture?: string;
    video?: string;
    galleries?: Gallery[];
    peopleList?: PeopleList[];
    graphDate?:string;
    years?: Year[];
    sectors?: Sector[];
    ages?: Age[];
    memberships?: Membership[];
    linksList?: LinkList[];
    facts?: Fact[];
    sections?: Section[];

    titleYear?: string;
    captionYear?: string;
    subtitleYear?: string;
    contentYear?: string;

    titleSectors?: string;
    captionSectors?: string;
    subtitleSectors?: string;
    contentSectors?: string;

    titleGroup?: string;
    captionGroup?: string;
    subtitleGroup?: string;
    contentGroup?: string;

    titleAge?: string;
    captionAge?: string;
    subtitleAge?: string;
    contentAge?: string;

    titleMembre?: string;
    captionMembre?: string;
    subtitleMembre?: string;
    contentMembre?: string;

    year?: string;
    lastYear?: string;
    tables?: Table[];

}

export class Post {
    type: string;
    slugs: object;
    title: string;
    pageTitle: string;
    subtitle: string;
    content: string;
    signature: string;
    color: string = "none";
    background: string  = "none";
    picture: string;
    video: string;
    galleries: Gallery[];
    peopleList: PeopleList[];
    graphDate:string;
    years: Year[];
    sectors: Sector[];
    ages: Age[];
    memberships: Membership[];
    linksList: LinkList[];
    facts: Fact[];
    sections: Section[];

    titleYear: string;
    captionYear: string;
    subtitleYear: string;
    contentYear: string;

    titleSectors: string;
    captionSectors: string;
    subtitleSectors: string;
    contentSectors: string;

    titleGroup: string;
    captionGroup: string;
    subtitleGroup: string;
    contentGroup: string;

    titleAge: string;
    captionAge: string;
    subtitleAge: string;
    contentAge: string;

    titleMembre: string;
    captionMembre: string;
    subtitleMembre: string;
    contentMembre: string;

    year: string;
    lastYear: string;
    tables: Table[];


    constructor(args: PostConstructor) {
        Object.keys(args).map(k =>  {

            switch(k){
                case "galleries" :
                    this.galleries = args[k].map( (element) => new Gallery(element) );
                    break;
                case "peopleList" :
                    this.peopleList = args[k].map( (element) => new PeopleList(element) );
                    break;
                case "years" :
                    this.years = args[k].map( (element) => new Year(element) );
                    break;
                case "sectors" :
                    this.sectors = args[k].map( (element) => new Sector(element) );
                    break;
                case "ages" :
                    this.ages = args[k].map( (element) => new Age(element) );
                    break;
                case "memberships" :
                    this.memberships = args[k].map( (element) => new Membership(element) );
                    break;
                case "facts" :
                    this.facts = args[k].map( (element) => new Fact(element) );
                    break;
                case "linksList" :
                    this.linksList = args[k].map( (element) => new LinkList(element) );
                    break;
                case "sections" :
                    this.sections = args[k].map( (element) => new Section(element) );
                    break;
                case "tables" :
                    this.tables = args[k].map( (element) => new Table(element) );
                    break;
                default:
                    this[k] = args[k]
            }

        });
    }





    public getSlug(lang:string){
        return this.slugs[lang];
    }


    public getLink(lang:string = uiState.lang){
        if(this.getSlug(lang))
            return "/" + [lang, this.getSlug(lang)].join("/");
        return "/" + lang;
    }


    @computed public get sectorsTotal(){
        return _.reduce(this.sectors, function(sum, sector) {
            return sum + sector.total;
        }, 0);
    }


}

import * as React from "react";
import _ = require("lodash");
import { Link } from "react-router-dom";
import { uiState } from "../app";

declare const CACHE_SERVICE_REMOTE: string;

export interface ImageProps {
    src: string;
    alt?: string;
    width?: number;
    height?: number;
    smart?: boolean;
    onLoad?: () => void;
}

export class Image extends React.Component<ImageProps, any> {
    render() {
        const { src, alt, width, height, smart, onLoad } = this.props;

        const service = "https://resize.hawaii.do";

        const transformations = (() => {
            let transform = [];

            transform.push(
                [width ? width : "", height ? height : ""].join("x")
            );
            if (smart) transform.push("sc");

            return transform.join(",");
        })();

        return (
            <img
                src={_
                    .compact([service, transformations, encodeURI(src)])
                    .join("/")}
                alt={alt}
                onLoad={onLoad}
            />
        );
    }
}

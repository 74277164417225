import * as React from "react";
import { observer } from "mobx-react";
import YouTube from "react-youtube";
import {uiState} from "../app";


@observer
export class PlayerWrapper extends React.Component<any, any> {
    render() {

        const opts = {
            playerVars: {
                autoplay: 0,
                controls: 1,
                showinfo: 0,
                modestbranding: 1,
                rel: 0
            }
        };

        const {players, activePlayer} = uiState;

        return <div id="player-wrapper">
                <div id="player-wrapper-content">	
                    {players.map((id) => <div key={id} className={(id === activePlayer) ? "active" : ""} >
                    <YouTube 
                        videoId={id}
                        opts={opts} 
                        onReady={ (event) => {
                            uiState.playersTarget[id] = event.target;
                            if(uiState.activePlayer === id) {
                                uiState.playVideo(id);
                            }
                        } }
                    />
                </div>)}
            </div>
        </div>
    }
}
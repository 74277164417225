import * as React from "react";
import _ = require("lodash");
import {uiState} from "../app";
import {GalleryBlock} from "../elements/GalleryBlock";
import {Post} from "../models/Post";
import {BaseView} from "./BaseView";


export class FactView extends BaseView  {

    render() {

        const {post} = this.state;

        return <section className="grid-text">

            <h1>{post.pageTitle}</h1>

            <div className="list-wrapper">

                {post.facts.map( (fact, index) =>
                    <div key={index} className="list-item">
                        <div className="list-title">
                            <span>{fact.number}</span>
                        </div>
                        <div className="list-text">
                            <p>
                                <span>{fact.highlight}</span>
                                {fact.content}
                            </p>
                        </div>
                    </div>
                )}

            </div>

        </section>
    }
}

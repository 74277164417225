import _ = require("lodash");
import {computed} from "mobx";
import {t} from "../store/I18nStore";

interface MembershipConstructor {
    number: number;
    difference: number;
}

export class Membership {
    number: number;
    difference: number;

    constructor(args: MembershipConstructor) {
        Object.keys(args).map(k =>  this[k] = args[k]);
    }
}

import * as React from "react";
import { observable, reaction, IObservableArray, computed } from "mobx";
import _ = require("lodash");
import * as Flickity from "flickity";
import { Media } from "../models/Media";
import { Image } from "./Image";
import { resizeGallery } from "./Flickity";

export interface CarouselProps {
    gallery: Media[];
    id: string;
}

export class Carousel extends React.Component<CarouselProps, any> {
    componentWillMount() {
        return this.setState({ index: 0 });
    }

    gallerySelector() {
        return `.${this.props.id}`;
    }

    componentDidMount() {
        const flickity = new Flickity(this.gallerySelector(), {
            cellSelector: "div",
            imagesLoaded: true,
            percentPosition: false,
            adaptiveHeight: true
        });

        flickity.on("cellSelect", this.handleCellSelect);

        this.setState({ flickity });
    }

    handleCellSelect = () => {
        this.setState({
            index: this.state.flickity.selectedIndex
        });
    };

    onImageLoad = () => {
        resizeGallery(this.gallerySelector());
    };

    componentWillUnmount() {
        if (!_.isNull(this.state)) this.state.flickity.destroy();
    }

    render() {
        const { gallery, id } = this.props;

        return (
            <div>
                <div className={[id, "js-flickity", "gallery"].join(" ")}>
                    {gallery.map(media => (
                        <div className="gallery-cell" key={media.slug}>
                            <Image
                                src={media.src}
                                alt={media.title}
                                width={1400}
                                smart={true}
                                onLoad={this.onImageLoad}
                            />
                        </div>
                    ))}
                </div>
                <p className="caption">{gallery[this.state.index].caption}</p>
            </div>
        );
    }
}

import * as React from "react";
import { DefaultComponentProps } from "../store/DefaultStoreInterface";
import _ = require("lodash");
import { Route, Switch } from "react-router";
import { observer } from "mobx-react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { uiState } from "../app";
import { ArrowContainer } from "../elements/ArrowContainer";
import { TitleReport } from "../svg/TitleReport";
import { PlayerWrapper } from "../elements/PlayerWrapper";
import { Loading } from "../svg/Loading";
import { HomeView } from "../views/HomeView";
import { RouteView } from "../views/RouteView";
import { CarouselView } from "../views/CarouselView";

@observer
export class Layout extends React.Component<any, any> {
    render() {
        if (!uiState.contentLoaded)
            return (
                <div className="first-load">
                    <Loading />
                </div>
            );
        const post = uiState.getPostBySlug(uiState.postSlug);

        const hasPost = !_.isUndefined(post);
        const hasMediaSlug = !_.isUndefined(uiState.mediaSlug);

        const pageClasses = {
            video: "video-display",
            page: "page",
            list: "page",
            facts: "page",
            people: "page",
            zahlen: "page",
            section: "page",
            bilanz: "page",
            gallery: "gallery-page"
        };

        const pageClass = (() => {
            switch (true) {
                case !hasPost:
                    return "home";
                case hasMediaSlug:
                    return "gallery-wrapper";
                case !_.isUndefined(pageClasses[post.type]):
                    return pageClasses[post.type];
                default:
                    return "home";
            }
        })();

        const wrapperClasses = ["wrapper", pageClass].join(" ");

        return (
            <div className={wrapperClasses}>
                <PlayerWrapper />

                <Header
                    router={this.props.router}
                    location={this.props.location}
                    history={this.props.history}
                >
                    {uiState.pendingRequest > 0 && (
                        <div className="loading">
                            <Loading />
                        </div>
                    )}
                    <div className="title-report">
                        /SIA20<span>16</span>
                    </div>
                    <ArrowContainer params={this.props.match.params} />
                </Header>
                <Switch>
                    <Route
                        exact
                        path={this.props.match.url + "/"}
                        component={HomeView}
                    />
                    <Route
                        exact
                        path={this.props.match.url + "/:postSlug"}
                        component={RouteView}
                    />
                    <Route
                        exact
                        path={this.props.match.url + "/:postSlug/:mediaSlug"}
                        component={CarouselView}
                    />
                </Switch>

                {hasPost &&
                    post.type !== "video" &&
                    !hasMediaSlug && <Footer post={post} />}
            </div>
        );
    }
}

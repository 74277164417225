import { observable, IObservableArray, computed } from "mobx";
import {PostTransportLayer, PostTransportLayerInterface} from "./PostTransportLayer";
import {Post} from "../models/Post";
import {StoreInterface, StoreType, replaceInStore} from "./StoreInterface";
import _ = require("lodash");
import {uiState} from "../app";
import {AbortedError} from "./error";

export interface PostStoreInterface extends StoreInterface {
    transportLayer: PostTransportLayerInterface;

    posts: Post[];
    load() : Promise<Post[]>;
}

export class PostStore {
    storeType = StoreType.PostStore;
    transportLayer: PostTransportLayerInterface;

    timestamp = 0;

    @observable posts: Post[] = [];
    @observable pendingRequests: number = 0;

    constructor(transportLayer: PostTransportLayerInterface) {
        this.transportLayer = transportLayer;
    }


    load(): Promise<Post[]> {
        this.pendingRequests += 1;
        this.timestamp += 1;
        const queryTimestamp = this.timestamp;

        return this.transportLayer
            .load()
            .then(k => {
                if(queryTimestamp === this.timestamp) {
                    this.posts = k;
                    this.pendingRequests -= 1;
                    uiState.contentLoaded = true;
                    return k;
                }
                else {
                    throw new AbortedError();
                }
            })
            .catch(x => {
                console.error(x); // Nice polyfill swallow error
                this.pendingRequests -= 1;
                throw x;
            })
    }

    updateFromServer(Post: Post) {
        replaceInStore(this.posts)(Post);
    }

}

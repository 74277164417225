import * as React from "react";
import _ = require("lodash");
import { uiState } from "../app";
import { Post } from "../models/Post";
import { DefaultComponentProps } from "../store/DefaultStoreInterface";
import * as Flickity from "flickity";
import { Image } from "../elements/Image";
import { resizeGallery } from "../elements/Flickity";

export class CarouselView extends React.Component<DefaultComponentProps, any> {
    gallerySelector = ".gallery";

    componentWillMount() {
        const { postSlug, mediaSlug } = this.props.match.params;
        uiState.postSlug = postSlug;
        uiState.mediaSlug = mediaSlug;

        const post = uiState.getPostBySlug(postSlug);
        if (_.isUndefined(post)) {
            this.props.history.push(uiState.translateUrl("/"));
            return;
        }

        const index = this.getMediaIndex(mediaSlug, post);
        if (index < 0) {
            this.props.history.push(post.getLink());
            return;
        }

        this.setState({ post, index });
    }

    componentDidMount() {
        if (_.isNull(this.state)) return;

        const flickity = new Flickity(this.gallerySelector, {
            cellSelector: "div",
            imagesLoaded: true,
            percentPosition: false,
            adaptiveHeight: true,
            initialIndex: this.state.index,
            prevNextButtons: true
        });

        flickity.on("cellSelect", this.handleCellSelect);
        this.setState({ flickity });
    }

    onImageLoad = () => {
        resizeGallery(this.gallerySelector);
    };

    componentWillReceiveProps(nextProps) {
        const { mediaSlug } = nextProps.match.params;
        const index = this.getMediaIndex(mediaSlug);

        if (!_.isUndefined(this.state.flickity))
            this.state.flickity.selectCell(index);
    }

    componentWillUnmount() {
        if (!_.isNull(this.state)) this.state.flickity.destroy();
        uiState.mediaSlug = undefined;
    }

    getMediaIndex = (slug: string, post: Post = this.state.post) =>
        _(post.galleries[0].medias).findIndex(media => media.slug === slug);

    handleCellSelect = () => {
        const index = this.state.flickity.selectedIndex;
        const url = [
            this.state.post.getLink(),
            this.state.post.galleries[0].medias[index].slug
        ].join("/");

        // avoid infinite loop with componentWillReceiveProps and cellSelect event
        if (this.props.history.location.pathname !== url)
            this.props.history.push(url);

        this.setState({ index });
    };

    render() {
        if (_.isNull(this.state)) return <div />;

        const gallery = this.state.post.galleries[0].medias;

        return (
            <div>
                <div className="gallery js-flickity">
                    {gallery.map(media => (
                        <div className="gallery-cell" key={media.slug}>
                            <Image
                                src={media.src}
                                alt={media.title}
                                width={2400}
                                onLoad={this.onImageLoad}
                            />
                        </div>
                    ))}
                </div>
                <p className="caption">{gallery[this.state.index].caption}</p>
            </div>
        );
    }
}

import * as React from "react";


export class Loading extends React.Component<any, any> {

    render() {
        return <svg xmlns="http://www.w3.org/2000/svg" width="120px" height="120px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <rect x="15" y="15" width="20" height="20" fill="#cec9c9">
                <animate attributeName="fill" from="#cec9c9" to="#3c302e" repeatCount="indefinite" dur="1s" begin="0.0s" values="#3c302e;#3c302e;#cec9c9;#cec9c9" keyTimes="0;0.1;0.2;1"/>
            </rect>
            <rect x="40" y="15" width="20" height="20" fill="#cec9c9">
                <animate attributeName="fill" from="#cec9c9" to="#3c302e" repeatCount="indefinite" dur="1s" begin="0.125s" values="#3c302e;#3c302e;#cec9c9;#cec9c9" keyTimes="0;0.1;0.2;1"/>
            </rect>
            <rect x="65" y="15" width="20" height="20" fill="#cec9c9">
                <animate attributeName="fill" from="#cec9c9" to="#3c302e" repeatCount="indefinite" dur="1s" begin="0.25s" values="#3c302e;#3c302e;#cec9c9;#cec9c9" keyTimes="0;0.1;0.2;1"/>
            </rect>
            <rect x="15" y="40" width="20" height="20" fill="#cec9c9">
                <animate attributeName="fill" from="#cec9c9" to="#3c302e" repeatCount="indefinite" dur="1s" begin="0.875s" values="#3c302e;#3c302e;#cec9c9;#cec9c9" keyTimes="0;0.1;0.2;1"/>
            </rect>
            <rect x="65" y="40" width="20" height="20" fill="#cec9c9">
                <animate attributeName="fill" from="#cec9c9" to="#3c302e" repeatCount="indefinite" dur="1s" begin="0.375" values="#3c302e;#3c302e;#cec9c9;#cec9c9" keyTimes="0;0.1;0.2;1"/>
            </rect>
            <rect x="15" y="65" width="20" height="20" fill="#cec9c9">
                <animate attributeName="fill" from="#cec9c9" to="#3c302e" repeatCount="indefinite" dur="1s" begin="0.75s" values="#3c302e;#3c302e;#cec9c9;#cec9c9" keyTimes="0;0.1;0.2;1"/>
            </rect>
            <rect x="40" y="65" width="20" height="20" fill="#cec9c9">
                <animate attributeName="fill" from="#cec9c9" to="#3c302e" repeatCount="indefinite" dur="1s" begin="0.625s" values="#3c302e;#3c302e;#cec9c9;#cec9c9" keyTimes="0;0.1;0.2;1"/>
            </rect>
            <rect x="65" y="65" width="20" height="20" fill="#cec9c9">
                <animate attributeName="fill" from="#cec9c9" to="#3c302e" repeatCount="indefinite" dur="1s" begin="0.5s" values="#3c302e;#3c302e;#cec9c9;#cec9c9" keyTimes="0;0.1;0.2;1"/>
            </rect>
        </svg>

    }
}

import "./polyfill";
import * as React from "react";
import * as ReactDOM from "react-dom";
import 'whatwg-fetch'
import  {Router, Route,Switch} from "react-router";
import {BrowserRouter} from "react-router-dom";
import DevTools, { configureDevtool } from 'mobx-react-devtools';
import {UiState} from "./store/UiState";
import {Api} from "./store/Api";
import {PostTransportLayer} from "./store/PostTransportLayer";
import {PostStore} from "./store/PostStore";
import {Layout} from "./views/Layout";
import {HomeView} from "./views/HomeView";
import {CarouselView} from "./views/CarouselView";
import {RouteView} from "./views/RouteView";
import * as  PiwikReactRouter from 'piwik-react-router';

import {polyfill as promisePolyfill} from'es6-promise';
import {polyfill as objectAssignPolyfill} from'es6-object-assign';
import {I18nStore, i18nStore} from "./store/I18nStore";

promisePolyfill();
objectAssignPolyfill();

declare const basePath: string;
declare const baseUrl: string;


// doesn't work with old version of Safari, etc.
// -> disable during tests.
const ENABLE_DEV_TOOL = false;
const api = new Api(baseUrl + "/wp-json/sia");

const postTransportLayer = new PostTransportLayer(api);
const postStore = new PostStore(postTransportLayer);

export const uiState = new UiState(api, postStore, i18nStore);

//export const history = browserHistory;

const extraProps = {  };

var piwik = PiwikReactRouter({
    url			: '//piwik.hawaii.do/',
    siteId		: 23
});

ReactDOM.render(
    <div>
        

        <BrowserRouter>
            <Switch>
                <Route path="/:lang" component={Layout} />
                <Route path="/" component={Layout} />
            </Switch>
        </BrowserRouter>
    </div>,
    document.getElementById("app")
);


import _ = require("lodash");
import {observable, reaction, computed} from "mobx";
import {uiState} from "../app";


interface GalleryConstructor {
    medias: Media[];
}

export class Gallery {
    medias: Media[];

    constructor(args: GalleryConstructor) {
        Object.keys(args).map(k =>  this[k] = args[k].map( (element) => new Media(element) ));
    }

}


interface MediaConstructor {
    title: string;
    caption?: string;
    slug: string;
    src: string;
}

export class Media {
    title: string;
    caption: string;
    slug: string;
    src: string;

    constructor(args: MediaConstructor) {
        Object.keys(args).map(k =>  this[k] = args[k]);
    }

}

import * as React from "react";


export interface IconGenderProps {
    type?: string;
}


export class IconMan extends React.Component<IconGenderProps, any> {

    render() {

        const classes = ["man", this.props.type].join(" ");

        return <span className={classes}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.1 12.8">
            <path d="M2.6 2.3c.6 0 1-.5 1-1.1 0-.6-.5-1.1-1-1.1-.6 0-1 .5-1 1.1-.1.6.4 1.1 1 1.1M4.5 3.3v-.1c0-.2-.2-.3-.4-.3H1c-.2-.1-.3.1-.4.3v.1L0 6.6c0 .2.1.4.3.5h.1c.2 0 .4-.1.4-.3l.3-1.6h.1v7.1c0 .3.2.6.6.6s.6-.2.6-.6V8.1h.5v4.1c0 .3.2.6.6.6s.6-.2.6-.6V5.1H4l.3 1.6c0 .2.3.4.5.3.2 0 .3-.2.3-.4l-.6-3.3z"/>
        </svg></span>
    }
}

export class IconWoman extends React.Component<IconGenderProps, any> {

    render() {

        const classes = ["woman", this.props.type].join(" ");

        return <span className={classes}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5 12.8">
            <path d="M2.5 2.3c.6 0 1-.5 1-1.1 0-.6-.4-1.1-1-1.1s-1 .5-1 1.1c0 .6.4 1.1 1 1.1M4.3 6.7c0 .2.2.3.3.3h.1c.2-.1.3-.3.3-.5L3.9 3c0-.2-.2-.3-.3-.3H1.3c-.2.1-.3.2-.3.3L0 6.5c0 .2.1.4.3.4.2 0 .4-.1.4-.3l.5-1.8h.4L.2 8.9h.9v3.3c0 .3.2.6.6.6s.6-.2.6-.6V8.9h.5v3.3c0 .3.2.6.6.6.3 0 .6-.2.6-.6V8.9h.9l-1.6-4h.4l.6 1.8z"/>
        </svg></span>
    }
}

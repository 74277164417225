import * as React from "react";
import {uiState} from "../app";
import _ = require("lodash");
import { observer } from "mobx-react";
import {DefaultComponentProps} from "../store/DefaultStoreInterface";
import {MasonryBlock, MasonryBlockBigLeft, MasonryBlockBigRight} from "../elements/MasonryBlock";

@observer
export class HomeView extends React.Component<DefaultComponentProps, any> {


    componentDidMount () {
        uiState.setTitle("");
        uiState.postSlug = null;
    }

    render() {

        if(uiState.postStore.posts.length === 0)
            return <div></div>;

        return <section className="masonry-container">
            { _.chunk(uiState.postStore.posts, 3).map((posts, index) =>
                <div key={index}>
                    { index === 0 &&
                        <MasonryBlockBigRight posts={posts} />}

                    { index === 3 &&
                        <MasonryBlockBigLeft posts={posts} />}

                    { index !== 0 &&  index !== 3 &&
                        <MasonryBlock posts={posts} />}
                </div>
            )}
        </section>
    }
}

import * as React from "react";


export class ArrowLeft extends React.Component<any, any> {

    render() {
        return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.5 21.5"><path d="M10.8 21.5L0 10.8 10.8 0l.7.7L1.4 10.8l10.1 10"/></svg>
    }
}

export class ArrowRight extends React.Component<any, any> {

    render() {
        return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.5 21.5"><path d="M.7 21.5l-.7-.7 10.1-10L0 .7.7 0l10.8 10.8"/></svg>
    }
}
import _ = require("lodash");
import {observable, reaction, computed} from "mobx";


interface LinkListConstructor {
    title: string;
    links: LinkElement[];
}

export class LinkList {
    title: string;
    links: LinkElement[];

    constructor(args: LinkListConstructor) {
        Object.keys(args).map(k =>  {
            if(k === "links")
                this.links = args[k].map( (element) => new LinkElement(element) );
            else
                this[k] = args[k]
        });
    }

}



interface LinkConstructor {
    name: string;
    link: string;
}

export class LinkElement {
    name: string;
    link: string;

    constructor(args: LinkConstructor) {
        Object.keys(args).map(k =>  this[k] = args[k]);
    }

}

import * as React from "react";
import {Media} from "../models/Media";
import {Post} from "../models/Post";
import {GalleryItem} from "./GalleryItem";

export interface GalleryBlockProps {
    gallery: Media[];
    post: Post;
}

export class GalleryBlock extends React.Component<GalleryBlockProps, any>  {

    render() {
        const {gallery, post} = this.props;

        return <div>
            <div className="row row-galerie">
                <GalleryItem media={gallery[0]} big={"left"} post={post} />
                {gallery[1] &&
                    <GalleryItem media={gallery[1]} post={post} />
                }
            </div>

            {gallery[2] &&
                <div className="row row-galerie">
                    <GalleryItem media={gallery[2]} post={post} />
                    {gallery[3] &&
                        <GalleryItem media={gallery[3]} big={"right"} post={post} />
                    }
                </div>
            }

            {gallery[4] &&
                <div className="row row-galerie">
                    <GalleryItem media={gallery[4]} post={post} />
                    {gallery[5] &&
                        <GalleryItem media={gallery[5]} post={post} />
                    }
                    {gallery[6] &&
                        <GalleryItem media={gallery[6]} post={post} />
                    }
                </div>
            }

        </div>
    }
}

import * as React from "react";
import { observer } from "mobx-react";
import _ = require("lodash");
import { uiState } from "../app";
import { Link } from "react-router-dom";
import { Media } from "../models/Media";
import { Post } from "../models/Post";
import { Image } from "./Image";

export interface GalleryItemProps {
    media: Media;
    post: Post;
    big?: string;
}

@observer
export class GalleryItem extends React.Component<GalleryItemProps, any> {
    static defaultProps = {
        big: false
    };

    render() {
        const { media, big, post } = this.props;

        if (_.isUndefined(media)) return <div />;

        const width = big ? 1400 : 800;
        const height = 800;

        const itemClasses = ["masonry-item", "item-big-" + big].join(" ");
        const articleClasses = ["masonry-item-content", "color-white"].join(
            " "
        );

        return (
            <Link
                to={[post.getLink(), media.slug].join("/")}
                className={itemClasses}
            >
                <article className={articleClasses}>
                    <Image
                        src={media.src}
                        alt={media.title}
                        height={height}
                        width={width}
                        smart={true}
                    />
                </article>
            </Link>
        );
    }
}

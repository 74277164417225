import * as React from "react";
import _ = require("lodash");
import {BaseView} from "./BaseView";
import {uiState} from "../app";
import * as Flickity from 'flickity';
import {Carousel} from "../elements/Carousel";

export class PageView extends BaseView {

    render() {
        const {postSlug} = this.props.match.params;
        const {post} = this.state;
        const contents = this.state.post.content.split("[gallery]");


        return <section className="grid-text">
            <h1>{post.pageTitle}</h1>


            {contents.map((content, index) => [
                <div className="content" key={index} dangerouslySetInnerHTML={{__html: content}} />,
                (!_.isUndefined(post.galleries[index])) ? <Carousel id={"gallery-"+postSlug+index} key={"gallery-"+postSlug+index} gallery={post.galleries[index].medias} /> : ""
            ])}

        </section>
    }
}

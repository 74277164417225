import _ = require("lodash");
import {computed} from "mobx";
import {t} from "../store/I18nStore";

interface SectionConstructor {
    code: string;
    civilEngineering: number;
    environment: number;
    technical: number;
    architecture: number;
}

export class Section {
    code: string;
    civilEngineering: number;
    environment: number;
    technical: number;
    architecture: number;

    constructor(args: SectionConstructor) {
        Object.keys(args).map(k =>  this[k] = args[k]);
    }

    @computed public get total(){
        return this.civilEngineering + this.environment + this.technical + this.architecture;
    }


    @computed public get name(){
        return t(`canton.${this.code}`);
    }


    @computed public get color(){
        if(this.total >= 850)
            return "red";

        if(this.total >= 470 && this.total < 850)
            return "red-80";

        if(this.total >= 200 && this.total < 470)
            return "red-60";

        return "red-40";
    }

}

import { observable, reaction, IObservableArray, computed } from "mobx";
import _ = require("lodash");
import { Api } from "./Api";
import { StoreInterface, StoreType } from "./StoreInterface";
import { PostStoreInterface } from "./PostStore";
import { Post } from "../models/Post";
//import  {history} from "../app";
import { AbortedError } from "./error";


export class UiState {
    private static get LOCAL_STORAGE_LANGUAGE_KEY() {
        return "language";
    }

    api: Api = null;

    @observable pendingRequest = 0;

    @observable contentLoaded = false;

    @observable postSlug;
    @observable mediaSlug;

    postStore: PostStoreInterface = null;

    public langs = {
        de: "D",
        fr: "F",
        it: "I"
    };

    @observable lang = "de";

    @observable players = [];
    @observable activePlayer = null;

    playersTarget = {};

    localStorageAvailable = true;

    constructor(api: Api, ...stores: StoreInterface[]) {
        try {
            localStorage.setItem("a", "a");
            localStorage.removeItem("a");
        } catch (e) {
            this.localStorageAvailable = false;
        }

        this.api = api;

        stores.forEach(store => {
            if (store.storeType === StoreType.PostStore)
                this.postStore = store as PostStoreInterface;

            reaction(
                () => store.pendingRequests,
                pendingRequest => {
                    this.pendingRequest = stores
                        .map(x => x.pendingRequests)
                        .reduce((acc, val) => acc + val, 0);
                }
            );
        });


        /**
         * Load translation from backend
         */

        let lang = this.retrieveLang();
        this.setLanguage(lang);
    }

    private isLangValid(lang: string) {
        if (_.isNull(lang)) return false;
        return this.langs[lang] !== undefined;
    }

    public setLanguage(newLang: string, history?) {
        const nextUrl = this.translateUrl(window.location.pathname, newLang);
        this.lang = newLang;

        this.api.setLanguage(newLang);

        if (this.localStorageAvailable)
            window.localStorage.setItem(
                UiState.LOCAL_STORAGE_LANGUAGE_KEY,
                newLang
            );

        this.postStore
            .load()
            .then(posts => {
                // IE9 doesn't like loop
                const isDifferentUrl = nextUrl !== window.location.pathname;

                if (
                    nextUrl &&
                    _.isString(nextUrl) &&
                    nextUrl.length > 0 &&
                    isDifferentUrl &&
                    history
                )
                    history.push(nextUrl);
            })
            .catch(x => {
                console.error(x); // Nice polyfill swallow error
                if (x instanceof AbortedError) return;
                else throw x;
            });
    }

    retrieveLang() {
        /**
         * Language selection
         * 1) from url
         * 2) from user preference in localStorage
         * 3) from browser language
         * 3) deutsch
         */

        const lang = this.getLanguageFormUrl(location.pathname);
        if (!_.isUndefined(lang) && this.isLangValid(lang)) return lang;

        if (this.localStorageAvailable) {
            const fromUser = localStorage.getItem(
                UiState.LOCAL_STORAGE_LANGUAGE_KEY
            );

            if (this.isLangValid(fromUser)) return fromUser;
        }

        const {
            navigator: { userLanguage, language }
        } = window as any;
        const rawBrowserLang = userLanguage || language;
        const browserLang = rawBrowserLang.substring(0, 2);

        if (this.isLangValid(browserLang)) return browserLang;

        return Object.keys(this.langs)[0];
    }

    private static get availableLanguages() {
        return ["de", "fr", "it"];
    }

    public getLanguageFormUrl(url: string) {
        return url.split("/")[1];
    }

    public translateUrl(url: string, code: string = this.lang) {
        const uri = url.split("/");

        const post = this.getPageContent();
        if (post && uri.length >= 3) return post.getLink(code);

        const key = uri[1];
        if (this.langs[key] !== undefined) uri[1] = code;
        else uri.splice(1, 0, code);

        return uri.join("/");
    }

    public getVideoPlayer(video: string) {
        if (this.players.indexOf(video) < 0) this.players.push(video);

        return this.playersTarget[video];
    }

    public playVideo(video: string) {
        const target = this.getVideoPlayer(video);
        if (target) target.playVideo();

        this.activePlayer = video;
    }

    public pauseVideo(video: string) {
        const target = this.getVideoPlayer(video);
        if (target) {
            target.pauseVideo();
            target.seekTo(0);
        }

        this.activePlayer = null;
    }

    public getPageContent(lang: string = this.lang) {
        const slug = location.pathname.split("/").slice(-1)[0];
        return this.postStore.posts.filter(
            post => post.getSlug(lang) === slug
        )[0];
    }

    public getPostBySlug(slug: string, lang: string = this.lang) {
        return this.postStore.posts.filter(
            post => post.getSlug(lang) === slug
        )[0];
    }

    public getPostIndex(slug: string, lang: string = this.lang) {
        //const [slug] = location.pathname.split("/").slice(-1);
        return _.findIndex(
            this.postStore.posts,
            post => post.getSlug(lang) === slug
        );
    }

    public getPreviousPost() {
        const index = this.getPostIndex(this.postSlug);
        if (index < 0) return this.postStore.posts[0];
        else if (index === 0) return this.postStore.posts.slice(-1)[0];

        return this.postStore.posts[index - 1];
    }

    public getNextPost() {
        const index = this.getPostIndex(this.postSlug);

        if (index < 0 || index === this.postStore.posts.length - 1)
            return this.postStore.posts[0];

        return this.postStore.posts[index + 1];
    }

    public getParentLocation() {
        const path = location.pathname.split("/");
        path.pop();
        return path.join("/");
    }

    setTitle(title: string = null) {
        document.title = _.compact([title, "SIA2016"]).join(" - ");
    }
}

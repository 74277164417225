import * as React from "react";
import _ = require("lodash");
import { observer } from "mobx-react";
import {BaseView} from "./BaseView";
import {uiState} from "../app";
import * as Flickity from 'flickity';
import {Doughnut, Bar} from "react-chartjs-2";
import {IconMan, IconWoman} from "../svg/IconGender";
import {Ease} from 'react-ease';
import {t} from "../store/I18nStore";
import * as Waypoint from "react-waypoint";


@observer
export class ZahlenView extends BaseView {
    refs: {
        membership: any,
        members: any,
        ages: any,
        scrollMembers: any,
        scrollAges: any
    };

    componentWillMount () {
        this.setState({
            year: this.state.post.years.length - 1,
            sector:0,
            category:0,
            age:0,
            gender:0,
            genderBottom: false,
            yearKey:0,
            sectorsKey:0,
            sectionKey:0,
            membershipKey:0
        })
    }

    componentDidMount() {
        const { year, category } = this.state;
    }

    setScrollPosition = ({ datasetIndex, index, ref, identifier }) => {
        const w: any = window; // FIXME, window.Chart not in the type definitions...
        const instance = _.find(w.Chart.instances, x => x.config.data.__identifier === [identifier, datasetIndex].join("_"))
        if(!instance)
            return;

        const { controller } = instance.getDatasetMeta(datasetIndex);
        const ruler = controller.getRuler();
        const { base } = controller.calculateBarIndexPixels(datasetIndex, index, ruler);
        return ref.scrollLeft = base - ref.clientWidth / 2;
    };

    render() {
        const { post, sector, year, category, age } = this.state;

        //1 : Architektur, 2: Technik, 3: Ingenieurbau, 4: Umwelt
        const classes = ["architecture", "technique", "genie-civil", "environnement"];
        const classesMembership = ["architecture", "gris1", "gris2", "gris3"];
        const sectorClass = classes[sector];

        const currentYearData = post.years[year];
        const currentAgeData = post.ages[age];

        const lastYear = post.years[post.years.length - 1];

        const categories = [t('section.einzelmitglieder'), t('section.firmenmitglieder')];
        const MEMBERS = 0;
        const COMPANIES = 1;

        const barOptions = {
            animation: {duration: 500},
            legend: {display: false},
            scales: {
                xAxes: [{
                    stacked: true,
                    gridLines: {display: false},
                    ticks: {display: false},
                    barPercentage: 0.5
                }],
                yAxes: [{
                    stacked: true,
                    gridLines: {
                        display: true,
                        color: '#DADADA',
                        lineWidth: 1,
                        tickMarkLength: 0,
                        borderDash: [2, 2]
                    },
                    ticks: {display: false}
                }]
            },
            tooltips: {
                position: 'nearest',
                backgroundColor: '#F0F0F0',
                cornerRadius: 0,
                displayColors: false,
                titleFontFamily: 'BebasNeue',
                titleFontColor: '#4D4D4D',
                titleFontSize: 26,
                bodyFontColor: '#4D4D4D',
                bodyFontSize: 20,
                xPadding: 10,
                yPadding: 12,
                callbacks: {
                    label: function(tooltipItem, data) {
                        const { index } = tooltipItem;
                        return data.datasets[0].data[index];
                    }
                }
            }
        };

        const buildBackgroundColor = data => (highlightColor, normalColor) =>
            data.map((year, index) => index === this.state.year ? highlightColor: normalColor);

        const buildYearsColors  = buildBackgroundColor(post.years);

        const membersDataset = {
            '__identifier': "members_0",
            labels: post.years.map(year => year.year),
            datasets: [{
                label: t("section.einzelmitglieder"),
                data: post.years.map(year => year.individualMembers),
                backgroundColor: buildYearsColors("#1a93cd", "#4D4D4D")
            },
            {
                label: t("section.assoziierte_mitglieder"),
                data: post.years.map(year => year.associateMembers),
                backgroundColor: buildYearsColors("#5fbbe7", "#6D6D6D")
            }]
        };


        const companiesDataset = {
            '__identifier': "members_1",
            labels: post.years.map(year => year.year),
            datasets: [{
                label: t("section.hauptsitz"),
                data: post.years.map(year => year.headquarters),
                backgroundColor: buildYearsColors("#80ba88", "#4D4D4D")
            },
            {
                label: t("section.zweigstelle"),
                data: post.years.map(year => year.branch),
                backgroundColor: buildYearsColors("#aedeb5", "#6D6D6D")
            }]
        };


        const agesDataset ={
            '__identifier': "ages_0",
            labels: post.ages.map(age => age.age),
            datasets: [{
                label: t("section.hauptsitz"),
                data: post.ages.map(age => age.number),
                backgroundColor: post.ages.map((age, index) => index === this.state.age ? "#D81926" : "#4D4D4D")
            }]
        };


        const professionLabels = ["architektur", "technik", "ingenieurbau", "umwelt"].map(x => t(`profession.${x}`));

        // with index

        // 1 archi, 2 ingénieur, 3 environnemnt, 4 technik
        const reorderMapping = {
            0: 0,
            1: 3,
            2: 1,
            3: 2
        };

        const sortedProfessionLabels = _.sortBy(professionLabels.map((x,i) => [x, i]), ([x, i]) => reorderMapping[i]);
        const sortedSectors = _.sortBy(post.sectors.map((x,i) => [x, i]), ([x, i]) => reorderMapping[i]);


        const membershipLabels = ["kleinstunternehmen", "kleinunternehmen", "mittelunternehmen", "grossunternehmen"].map(x => t(`stats.${x}`));

        const setYear = year => {
            this.setScrollPosition({ datasetIndex: category, index: year, ref: this.refs.scrollMembers, identifier: "members" });
            this.setState({ year });
        };

        const setAge = age => {
            this.setScrollPosition({ datasetIndex: 0, index: age, ref: this.refs.scrollAges, identifier: "ages" });
            this.setState({ age });
        };

        return <div className="graphes-wrapper">
            <section className="graphe donut">

                <h1>{post.titleGroup}</h1>
                <div className="small-date">{post.graphDate}</div>
                <h3>{post.subtitleGroup}</h3>
                <div className="content" dangerouslySetInnerHTML={{__html: post.contentGroup}} />

                <Waypoint
                    onEnter={ () => this.setState({ sectorsKey: this.state.sectorsKey + 1 }) }
                    topOffset="-50%"
                />
                <div key={this.state.sectorsKey} className="graphe-infos">
                    <div className="graphe-canvas">
                        <div id="myDonut">
                            <Doughnut ref='secors'
                                width={407}
                                height={407}
                                data={{
                                    labels: sortedProfessionLabels.map(([label, i]) => label),
                                    datasets: [{
                                        data: sortedSectors.map(([sector, i]) => sector.total),
                                        backgroundColor: ["#56524E", "#BD5E4C", "#AFD2D7", "#DAA529"],
                                        hoverBackgroundColor: ["#56524E", "#BD5E4C", "#AFD2D7", "#DAA529"],
                                        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"]
                                    }]
                                }}
                                options={{
                                    cutoutPercentage: 80,
                                    animation: {
                                        easing: 'easeOutExpo',
                                        duration: 2000
                                    },
                                    legend: false,
                                    tooltips: {
                                        position: 'nearest',
                                        backgroundColor: '#F0F0F0',
                                        cornerRadius: 0,
                                        displayColors: false,
                                        bodyFontColor: '#4D4D4D',
                                        bodyFontSize: 16,
                                        xPadding: 10,
                                        yPadding: 12
                                    }
                                }} />
                        </div>
                        <div className="total number-index">
                            <span>{ t('stats.gesamtzahl') }</span>

                            <Ease from={0} to={post.sectorsTotal} duration={1500}>
                                {val => <span className="counter">{parseInt(val)}</span>}
                            </Ease>

                        </div>
                    </div>
                    <div className="graphe-facts">
                        <ul>
                            {
                                sortedProfessionLabels.map(([label, i]) =>
                                    <li key={i}>
                                        <span>{ label }</span>
                                        <span className={ classes[i] }>{post.sectors[i].total}</span>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </section>

            <section className="graphe bars">

                <h1>{post.titleYear}</h1>
                <div className="small-date">{post.graphDate}</div>
                <h3>{post.subtitleYear}</h3>
                <div className="content" dangerouslySetInnerHTML={{__html: post.contentYear}} />

                <Waypoint
                    onEnter={ () => {
                        this.setState({ yearKey: this.state.yearKey + 1 });
                        const { year, category } = this.state;
                        this.setScrollPosition({ datasetIndex: category, index: year, ref: this.refs.scrollMembers, identifier: "members" });
                    }}
                    topOffset="-50%"
                />
                <div key={this.state.yearKey} className="graphe-infos">
                    <div className="graphe-canvas" ref='scrollMembers'>

                        <div className="scroll-area">
                            <Bar ref='members'
                                data={category === MEMBERS
                                    ? membersDataset
                                    : companiesDataset
                                }
                                options={barOptions}
                                onElementsClick={element => element && element[0] ? setYear(parseInt(element[0]._index)) : undefined }
                            />
                        </div>

                    </div>
                    <div className="graphe-facts">
                        <div className="year-select-container">
                            <div className="year-select">
                                <span>{ t('section.mitglieder') }</span>
                                <div className="year">
                                    <select onChange={e => this.setState({category: parseInt(e.target.value)}) }  value={category}>
                                        {categories.map((category, index) =>
                                            <option key={category} value={index}>{category}</option>
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className="year-select">
                                <span>{ t('section.jahr') }</span>
                                <div className="year">
                                    <select onChange={e => setYear(parseInt(e.target.value)) }  value={year}>
                                        {post.years.map((year, index) =>
                                            <option key={year.year} value={index}>{year.year}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>


                        { category === MEMBERS &&
                            <ul key={category+this.state.year}>
                                <li>
                                    <span>{ t('section.mitglieder') }</span>
                                </li>
                                <li className="blue">
                                    <span>{ t('section.einzelmitglieder') }</span>
                                    <Ease from={0} to={currentYearData.individualMembers} duration={1500}>
                                        {val => <span>{parseInt(val)}</span>}
                                    </Ease>
                                </li>
                                <li className="blue">
                                    <span>{ t('section.assoziierte_mitglieder') }</span>
                                    <Ease from={0} to={currentYearData.associateMembers} duration={1500}>
                                        {val => <span>{parseInt(val)}</span>}
                                    </Ease>
                                </li>
                                <li>
                                    <span>{ t('stats.gesamtzahl') }</span>
                                    <Ease from={0} to={currentYearData.totalMembers} duration={1500}>
                                        {val => <span>{parseInt(val)}</span>}
                                    </Ease>
                                </li>
                            </ul>}

                        { category === COMPANIES &&
                            <ul key={category+this.state.year}>
                                <li>
                                    <span>{ t('section.mitglieder') }</span>
                                </li>
                                <li className="green">
                                    <span>{ t('section.hauptsitz') }</span>
                                    <Ease from={0} to={currentYearData.headquarters} duration={1500}>
                                        {val => <span>{parseInt(val)}</span>}
                                    </Ease>
                                </li>
                                <li className="green">
                                    <span>{ t('section.zweigstelle') }</span>
                                    <Ease from={0} to={currentYearData.branch} duration={1500}>
                                        {val => <span>{parseInt(val)}</span>}
                                    </Ease>
                                </li>
                                <li>
                                    <span>{ t('stats.gesamtzahl') }</span>
                                    <Ease from={0} to={currentYearData.totalOffices} duration={1500}>
                                        {val => <span>{parseInt(val)}</span>}
                                    </Ease>
                                </li>
                            </ul>}

                    </div>
                </div>

            </section>

            <section className="graphe bars gender">

                <h1>{post.titleSectors}</h1>
                <div className="small-date">{post.graphDate}</div>
                <h3>{post.subtitleSectors}</h3>
                <div className="content" dangerouslySetInnerHTML={{__html: post.contentSectors}} />

                <Waypoint
                    onEnter={ () => {
                        if(!this.state.genderBottom){
                            this.setState({ gender: this.state.gender + 1 });
                        }
                        this.setState({ genderBottom: false });
                    }}
                />
                <div key={this.state.gender} className="graphe-infos">
                    <div className="graphe-facts">
                        <div className="year-select">
                            <span>{ t('stats.berufsgruppen') } </span>
                            <div className="year">

                                <select onChange={e => this.setState({sector: parseInt(e.target.value)}) }  value={sector}>
                                    { sortedProfessionLabels.map(([label, i]) => <option key={i} value={i}>{ label }</option>) }
                                </select>

                            </div>
                        </div>
                        <ul>
                            <li className={sectorClass}>
                                <span>{ t('stats.frauen') }</span>

                                <Ease key={"ease1"+sector} from={0} to={post.sectors[sector].womenNumber} duration={1500}>
                                    {val => <span>{parseInt(val)}</span>}
                                </Ease>

                                <Ease key={"ease2"+sector} from={0} to={post.sectors[sector].womenPercent} duration={1500}>
                                    {val => <span>{(Math.round(val*10)/10).toFixed(1).toString().replace(".", ",")}%</span>}
                                </Ease>


                            </li>
                            <li className={sectorClass}>
                                <span>{ t('stats.manner') }</span>

                                <Ease key={"ease1"+sector} from={0} to={post.sectors[sector].menNumber} duration={1500}>
                                    {val => <span>{parseInt(val)}</span>}
                                </Ease>

                                <Ease key={"ease2"+sector} from={0} to={post.sectors[sector].menPercent} duration={1500}>
                                    {val => <span>{(Math.round(val*10)/10).toFixed(1).toString().replace(".", ",")}%</span>}
                                </Ease>
                            </li>
                        </ul>
                    </div>

                    <div className="gender-canvas">

                        <div className="gender-canvas-wrapper">
                            <Ease key={sector} from={0} to={post.sectors[sector].charactersDisplay} duration={1500}>
                                {val => <span>
                                    {_.times(parseInt(val), _.constant(1)).map((el, index) => {
                                        return (index < post.sectors[sector].womenCharacters)
                                            ? <IconWoman key={index} type={sectorClass} />
                                            : <IconMan key={index} type={sectorClass} />
                                    })}
                                </span>}
                            </Ease>
                        </div>
                    </div>

                </div>
                <Waypoint
                    onEnter={ () => { this.setState({ genderBottom: true }) }}
                / >
            </section>





            <section className="graphe bars">

                <h1>{post.titleAge}</h1>
                <div className="small-date">{post.graphDate}</div>
                <h3>{post.subtitleAge}</h3>
                <div className="content" dangerouslySetInnerHTML={{__html: post.contentAge}} />

                <Waypoint
                    onEnter={ () => {
                        this.setState({ sectionKey: this.state.sectionKey + 1 });
                        const { age } = this.state;
                        this.setScrollPosition({ datasetIndex: 0, index: age, ref: this.refs.scrollAges, identifier: "ages" });
                    }}
                    topOffset="-50%"
                />
                <div key={this.state.sectionKey} className="graphe-infos">
                    <div className="graphe-canvas" ref="scrollAges">

                        <div className="scroll-area">
                            <Bar ref='ages'
                                data={agesDataset}
                                options={barOptions}
                                onElementsClick={(element) => setAge(parseInt(element[0]._index)) }
                            />
                        </div>

                    </div>
                    <div className="graphe-facts">
                        <div className="year-select-container">

                            <div className="year-select">
                                <span>{ t('section.alter') }</span>
                                <div className="year">
                                    <select onChange={e => setAge(parseInt(e.target.value)) }  value={age}>
                                        {post.ages.map((age, index) =>
                                            <option key={index} value={index}>{age.age}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>


                        <ul key={this.state.age}>
                            <li>
                                <span>{ t('section.mitglieder') }</span>
                            </li>
                            <li>
                                <span>{ t('stats.gesamtzahl') }</span>
                                <Ease from={0} to={currentAgeData.number} duration={1500}>
                                    {val => <span>{parseInt(val)}</span>}
                                </Ease>
                            </li>
                        </ul>

                    </div>
                </div>

            </section>



            <section className="graphe donut">

                <h1>{post.titleMembre}</h1>
                <div className="small-date">{post.graphDate}</div>

                <h3>{post.subtitleMembre}</h3>
                <div className="content" dangerouslySetInnerHTML={{__html: post.contentMembre}} />



                <Waypoint
                    onEnter={ () => this.setState({ membershipKey: this.state.membershipKey + 1 }) }
                    topOffset="-50%"
                />
                <div key={this.state.membershipKey} className="graphe-infos">
                    <div className="graphe-canvas">
                        <div id="myDonut">
                            <Doughnut ref='membership'
                                width={407}
                                height={407}
                                data={{
                                    labels: membershipLabels,
                                    datasets: [{
                                        data: post.memberships.map(membership => membership.number ),
                                        backgroundColor: ["rgba(77, 77, 77, 1)", "rgba(77, 77, 77, 0.7)", "rgba(77, 77, 77, 0.4)", "rgba(77, 77, 77, 0.2)"],
                                        hoverBackgroundColor: ["rgba(66, 66, 66, 1)", "rgba(77, 77, 77, 0.8)", "rgba(77, 77, 77, 0.5)", "rgba(77, 77, 77, 0.3)"],
                                        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"]
                                    }]
                                }}
                                options={{
                                    cutoutPercentage: 80,
                                    animation: {
                                        easing: 'easeOutExpo',
                                        duration: 2000
                                    },
                                    legend: false,
                                    tooltips: {
                                        position: 'nearest',
                                        backgroundColor: '#F0F0F0',
                                        cornerRadius: 0,
                                        displayColors: false,
                                        bodyFontColor: '#4D4D4D',
                                        bodyFontSize: 16,
                                        xPadding: 10,
                                        yPadding: 12
                                    }
                                }} />
                        </div>
                    </div>
                    <div className="graphe-facts">
                        <ul>
                            {
                                membershipLabels.map((label, i) =>
                                    <li key={i}>
                                        <span>{ label }</span>
                                        <span className={ classesMembership[i] }>{post.memberships[i].number}</span>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>



                <div className="list-wrapper">

                    <div className="list-item">
                        <div className="list-title">
                            <span>{lastYear.branch}</span>
                        </div>
                        <div className="list-text">
                            <p>{t("section.zweigstelle")}</p>
                        </div>
                    </div>

                    <div className="list-item">
                        <div className="list-title">
                            <span>{lastYear.totalOffices}</span>
                        </div>
                        <div className="list-text">
                            <p>{t("section.firmenmitglieder_inkl")}</p>
                        </div>
                    </div>

                    <div className="list-item">
                        <div className="list-title">
                            <span>{lastYear.headquarters}</span>
                        </div>
                        <div className="list-text">
                            <p>{t("section.firmenmitglieder_exl")}</p>
                        </div>
                    </div>

                </div>
            </section>




        </div>
    }
}

import * as React from "react";
import _ = require("lodash");
import {BaseView} from "./BaseView";
import {uiState} from "../app";
import {SwissMap} from "../svg/SwissMap";
import {Pill} from "../elements/Pill";
import {Section} from "../models/Section";
import {SectionFacts} from "../elements/SectionFacts";
import {Ease} from 'react-ease';
import {observer} from "mobx-react";
import {t} from "../store/I18nStore";

@observer
export class SectionView extends BaseView {
    componentWillMount () {
        this.setState({canton:undefined})
    }

    componentDidMount () {
        super.componentDidMount();
        this.setState({ canton: SectionView.sortSections(this.state.post.sections)[0].code })
    }

    static sortSections(sections: Section[]) {
        return sections
            .slice(0)
            .sort((a,b) => a.name.localeCompare(b.name));
    }


    render() {
        const { post, canton } = this.state;
        const current: Section = post.sections.filter(({ code }, index) => index === 0 || code === canton).pop();

        const captions = [
            { colorClass: "bg-red-40",  label: "100-199"},
            { colorClass: "bg-red-60",  label: "200–469"},
            { colorClass: "bg-red-80",  label: "470–849"},
            { colorClass: "bg-red", label: "850–4'500"},
        ];

        const Caption = ({ colorClass, label }) =>
            <div className="caption">
                <span className={ "pill " + colorClass } /><span>{label}</span>
            </div>;

        const buildPill = (s: Section) =>
            <Pill active={s.code === canton} section={s} update={() => this.setState({canton: s.code}) } />;

        const buildFacts = (s: Section) =>
            s.code === canton && <SectionFacts section={s}>{s.name}</SectionFacts>;

        const sections = SectionView.sortSections(post.sections);

        return <section className="graphe bars section">
            <h1>{post.pageTitle}</h1>
            <div className="graphe-infos">
                <div className="graphe-canvas">
                    <SwissMap />
                    <div className="pills-wrapper">
                        <Ease from={1} to={post.sections.length} duration={2000}>
                        {val =>
                            _.chunk( post.sections.slice(0, parseInt(val)), 3 ).map((sections) =>
                                [].concat(sections.map(buildPill), sections.map(buildFacts))
                            )}
                        </Ease>
                    </div>
                </div>
                <div className="graphe-caption">
                    <div className="subtitle">{ t("section.mitglieder") }</div>
                    <div className="caption-wrapper">{ captions.map(Caption) }</div>
                </div>

                <SectionFacts section={current}>
                    <select onChange={(e) => this.setState({canton: e.target.value}) } value={this.state.canton}>
                        {sections.map(section =>
                            <option key={section.code} value={section.code}>{section.name}</option>
                        )}
                    </select>
                </SectionFacts>
            </div>
        </section>
    }
}

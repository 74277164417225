import * as React from "react";


export class IconVideo extends React.Component<any, any> {

    render() {
        return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.1 43.1">
              <path d="M21.5 0C9.6 0 0 9.6 0 21.5S9.6 43 21.5 43 43 33.4 43 21.5C43.1 9.6 33.4 0 21.5 0zm10.6 22.4l-15.4 8.9c-.6.4-1.4-.1-1.4-.8V12.6c0-.7.8-1.2 1.4-.8l15.4 8.9c.6.4.6 1.3 0 1.7z"/>
        </svg>
    }
}

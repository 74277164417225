import {Api} from "./Api";
import {Post} from "../models/Post";
import { PostStoreInterface } from "./PostStore";
export interface PostTransportLayerInterface {
    api: Api
    load()
}

export class PostTransportLayer implements PostTransportLayerInterface {
    api: Api;
    store: PostStoreInterface;

    constructor(api: Api) {
        this.api = api;
    }

    setStore(store: PostStoreInterface) {
        this.store = store;
    }

    load() {
        return this.api.getCollection(`/posts`)()(Post, this.store);
    }
}

import * as React from "react";
import _ = require("lodash");
import {uiState} from "../app";
import {BaseView} from "./BaseView";

export class VideoView extends BaseView {


    componentWillMount(){
        if(this.state)
            uiState.playVideo(this.state.post.video);
    }
    
    componentWillUnmount(){
        if(this.state)
            uiState.pauseVideo(this.state.post.video);
    }

    componentDidUpdate(prevProps, prevState){
        uiState.pauseVideo(prevState.post.video);
        uiState.playVideo(this.state.post.video);
    }

    render() {
        return <div></div>;
    }
}
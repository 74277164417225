import _ = require("lodash");
import {observable, reaction, computed} from "mobx";

interface FactConstructor {
    number: number;
    highlight?: string;
    content: string;
}

export class Fact {
    number: number;
    highlight: string;
    content: string;

    constructor(args: FactConstructor) {
        Object.keys(args).map(k =>  this[k] = args[k]);
    }

}

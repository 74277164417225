import _ = require("lodash");

export enum StoreType {
    PostStore,
    I18nStore
}

// Generic interface to watch change in the different stores
export interface StoreInterface {
    pendingRequests: number;
    storeType: StoreType;
}

export const replaceInStore = store => newRecord => {
    const recordInStore = store.find(record => record.id === newRecord.id);

    if(recordInStore) {
        store.remove(recordInStore)
    }
    store.push(newRecord)
};

import _ = require("lodash");
import { computed } from "mobx";

interface SectorConstructor {
    menNumber: number;
    womenNumber: number;
    charactersDisplay: number;
}

export class Sector {
    menNumber: number;
    womenNumber: number;
    charactersDisplay: number;

    constructor(args: SectorConstructor) {
        Object.keys(args).map(k =>  {
            this[k] = args[k]
        });
    }


    @computed public get total(){
        return this.menNumber + this.womenNumber;
    }

    @computed public get womenPercent(){
        return Math.round(this.womenNumber / this.total * 1000) / 10;
    }

    @computed public get menPercent(){
        return Math.round(this.menNumber / this.total * 1000) / 10;
    }


    @computed public get womenCharacters(){
        return Math.round(this.womenNumber / this.total * this.charactersDisplay);
    }

    @computed public get menCharacters(){
        return Math.round(this.menNumber / this.total * this.charactersDisplay);
    }

}

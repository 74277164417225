import * as React from "react";
import _ = require("lodash");
import { observer } from "mobx-react";
import  {Link} from "react-router-dom";
import {uiState} from "../app";
import {Post} from "../models/Post";

interface FooterProps {
    post?: Post
}

export class Footer extends React.Component<FooterProps, any> {
    render() {
        const { posts } = uiState.postStore;

        const indexes = _(posts)
            .filter(post => post !== this.props.post)
            .map((post, index) => index)
            .shuffle()
            .take(3)
            .value();

        return <footer>
            <div className="more-tiles-container">
                { indexes.map(index =>
                    <div key={index} className="more-tile-content">
                        <Link to={posts[index].getLink()} className="more-tile">
                            <h2 dangerouslySetInnerHTML={{__html: posts[index].title}} />
                            <div className="more-tile-subtitle">
                                {posts[index].subtitle}
                            </div>
                        </Link>
                    </div>
                )}
            </div>
        </footer>
    }
}

import {computed} from "mobx";
declare const require: {
    <T>(path: string): T;
    (paths: string[], callback: (...modules: any[]) => void): void;
    ensure: (paths: string[], callback: (require: <T>(path: string) => T) => void) => void;
};

import {StoreInterface, StoreType} from "./StoreInterface";
import {get, isUndefined} from "lodash";
import {uiState} from "../app";
/**
 * Mandatory to do it with require since
 * typescript want modules with the import syntax.
 */
const de = require('../locales/de.json');
const fr = require('../locales/fr.json');
const it = require('../locales/it.json');

export interface I18nStoreInterface extends StoreInterface {}

export class I18nStore implements I18nStoreInterface{
    pendingRequests = 0;
    storeType = StoreType.I18nStore;

    @computed
    get language() {
        return { de, fr, it }[uiState.lang];
    }

    translate(path: String) {
        const result = get(this.language, path);

        if(isUndefined(result)) {
            // TODO log
        }

        return isUndefined(result) ? path : result;
    }
}

export const i18nStore = new I18nStore();
export const t = (path: String) => i18nStore.translate(path);

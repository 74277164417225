import * as React from "react";
import  {Link} from "react-router-dom";
import {uiState} from "../app";
import {LogoSia} from "../svg/LogoSia";
import {observer} from "mobx-react";

@observer
export class Header extends React.Component<any, any> {

    render() {

        const {lang, langs} = uiState;

        const setLanguage = (e, newLang: string) => {
            e.preventDefault();
            uiState.setLanguage(newLang, this.props.history);
        };

        return  <header>
            <Link id="logo-sia" className="btn btn-default" to={ uiState.translateUrl("/") }>
                <LogoSia />
            </Link>

            {this.props.children}


            <div className="lang-container">
                { Object.keys(langs).map((code) => 
                    <a key={code} href={uiState.translateUrl(location.pathname, code)} onClick={ (e) => setLanguage(e, code) }>
                        <span key={code} className={ code === lang ? "active" : "" }>
                            { langs[code] }
                        </span>
                    </a>) }
            </div>
 
            <div id="close-wrapper">
                <Link id="stop" className="close" to={ uiState.getParentLocation() }>
                    <span></span>
                    <span></span>
                </Link>
            </div>

        </header>
    }
}
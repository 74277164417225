export function HttpError(status, message) {
    this.name = 'HttpError';
    this.message = message;
    this.status = status;
    let err: any;
    err = new Error();
    this.stack = err.stack;
}
HttpError.prototype = new Error;

export function ProcessingError(message) {
    this.name = 'ProcessingError';
    this.message = message;
    let err: any;
    err = new Error();
    this.stack = err.stack;
}
ProcessingError.prototype = new Error;

/**
 * To wrap error to detect second and less serious error
 * @param error
 * @constructor
 */
export function SecondRequestError(error: Error) {
    this.name = 'SecondRequestError';
    this.innerError = error;
}

SecondRequestError.prototype = new Error;


/**
 * To "abort" a promise
 * @constructor
 */
export function AbortedError() {
    this.name = 'Aborted';
}
AbortedError.prototype = new Error;

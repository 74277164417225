import Map from 'es6-map'

const w: any = window;
if(!w.Map) {
    w.Map = Map;
}

// IE9 fix
if(!window.console) {
    let w: any = window;
    w.console = {
        log : function(){},
        warn : function(){},
        error : function(){},
        time : function(){},
        timeEnd : function(){}
    }
}

import _ = require("lodash");
import {computed} from "mobx";
import {t} from "../store/I18nStore";

interface AgeConstructor {
    age: string;
    number: number;
}

export class Age {
    age: string;
    number: number;

    constructor(args: AgeConstructor) {
        Object.keys(args).map(k =>  this[k] = args[k]);
    }
}

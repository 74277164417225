import * as React from "react";
import _ = require("lodash");
import {BaseView} from "./BaseView";
import {uiState} from "../app";
import * as Flickity from 'flickity';
import {PersonItem} from "../elements/PersonItem";

export class PeopleView extends BaseView {

    render() {

        const {post} = this.state;

        return <section className="leute">
            <h1>{this.state.post.pageTitle}</h1>

            {post.peopleList.map( (people, index) => <div key={index} className="masonry-container leute-wrapper">
                <h1>{people.category}</h1>
                {people.people.map((person) => <PersonItem key={person.name} person={person} />)}
            </div>)}

        </section>
    }
}

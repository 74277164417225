import * as React from "react";
import _ = require("lodash");
import {BaseView} from "./BaseView";
import {PageView} from "./PageView";
import {VideoView} from "./VideoView";
import {GalleryView} from "./GalleryView";
import {ZahlenView} from "./ZahlenView";
import {SectionView} from "./SectionView";
import {PeopleView} from "./PeopleView";
import {FactView} from "./FactView";
import {ListView} from "./ListView";
import {BilanzView} from "./BilanzView";

export class RouteView extends BaseView {
    render() {

        const typesToComponent = {
            page: PageView,
            video: VideoView,
            gallery: GalleryView,
            zahlen: ZahlenView,
            section: SectionView,
            people: PeopleView,
            facts: FactView,
            list: ListView,
            bilanz: BilanzView
        };

        const Component = typesToComponent[this.state.post.type];

        const { history, match } = this.props;

        if(Component)
            return <Component history={history} match={match} />;
        else
            return <div></div>;
    }
}

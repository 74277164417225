import * as React from "react";
import {Link} from "react-router-dom";
import {uiState} from "../app";
import {ArrowLeft, ArrowRight} from "../svg/IconArrows";

export class ArrowContainer extends React.Component<any, any> {

    render() {
        return <div className="arrows-container">
            <Link to={uiState.getPreviousPost().getLink()}>
                <ArrowLeft />
            </Link>
            <Link to={uiState.getNextPost().getLink()}>
                <ArrowRight />
            </Link>
        </div>
    }
}

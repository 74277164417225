import * as React from "react";


export class SwissMap extends React.Component<any, any> {


    render() {

        const style = ".st0{fill:#EDEDED;}";

        return <svg xmlns="http://www.w3.org/2000/svg" version="1" x="0" y="0" viewBox="0 0 426 271" className="swiss-map">
            <style type="text/css">{style}</style>
            <polygon className="st0" points="299 6 300 17 292 17 292 10 280 10 281 16 273 22 273 11 260 10 249 0 232 6 231 21 244 23 233 32 223 33 210 24 199 35 184 35 184 30 172 29 174 34 165 40 159 34 145 35 148 40 137 51 118 51 120 43 98 43 91 60 101 57 107 65 94 72 95 79 67 108 45 119 53 129 47 130 46 142 14 166 19 173 12 188 18 195 15 209 0 215 2 227 17 227 36 215 36 208 29 208 36 194 41 199 53 191 82 191 78 197 87 210 80 233 92 239 108 264 126 257 135 259 152 247 181 256 185 246 197 244 196 231 209 224 204 203 216 200 220 188 235 183 233 200 238 215 255 235 272 235 267 246 288 271 296 261 295 252 291 252 291 243 296 243 296 227 314 206 313 177 328 177 327 191 340 203 357 204 354 196 375 193 384 205 383 212 399 207 391 194 397 186 394 180 386 183 385 168 391 157 403 157 400 162 423 170 426 155 415 149 426 114 409 104 407 110 398 112 397 123 390 126 380 118 367 114 368 106 349 98 333 98 334 86 328 82 346 56 339 41 347 42 352 37 327 17 "/>
        </svg>
    }
}
